import React, { useState } from 'react';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [values,setValues]=useState({
    PersonName:'',
    FatherName:'',
    VillageName:'',
    ChaukleName:'',
    MembershipType:'',
    Amount:'',
    PaymentMode:'',
    PhoneNumber:'',
    AcceptedBy:''
  })

  const [apiResp,setApiResp]=useState();
const navigate=useNavigate();
  const handleInput=(event)=>{
    console.warn(event.target.name +":"+event.target.value )
   setValues(prev=> ({...prev,[event.target.name]:event.target.value}))
  }

  const handleSubmit=(event)=>{
    event.preventDefault();
    
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(values);
    // console.warn(raw);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://malecontechnologies.site/api/createData", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        
        setApiResp(result)
        if(apiResp.Status=="Success")
        {
          navigate("/MemberList");
        }
      })
      .catch((error) => setApiResp(error));

      // if(apiResp.affectedRows==1)
      // {
      //   navigate("/Receipt");
      // }
      console.warn(apiResp);
  }


  // const handleKeyPress = (event) => {
  //   const charCode = event.charCode;
  //   console.warn("charcode : ",charCode);
  //   if (!(charCode >= 48 && charCode <= 57)) {
  //     event.preventDefault();
  //   }

  //   //onkeypress='return event.charCode>=48 && event.charCode<=57'
  // }

  return (
<div>
    <section className="h-100">
  <div className="container py-5 h-100">
    
  <NavBar></NavBar>
  
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col">
        <div className="card card-registration my-4">
          <div className="row g-0 justify-content-center">
           <form method='post' onSubmit={handleSubmit}>
            <div className="col-xl-12">
              <div className="card-body p-md-5 text-black">
                <h3 className="text-uppercase text-center">Generate New Receipt</h3>
                <h3 className="mb-5 text-uppercase text-center">नई रसीद बनाएं</h3>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label"   for="form3Example1m">Person name / दानदाता का नाम</label>
                      <input type="text" id="form3Example1m" onChange={handleInput} name="PersonName" className="form-control form-control-lg" />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1n">Father name / पिता का नाम</label>
                      <input type="text" id="form3Example1n"  onChange={handleInput} name="FatherName" className="form-control form-control-lg" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1m1">Village / गाँव </label>
                      <input type="text" id="form3Example1m1"  onChange={handleInput} name="VillageName" className="form-control form-control-lg" />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1n1">Chaukle / चौकला </label>
                      <select className="select form-control"  onChange={handleInput} name="ChaukleName">
                      <option value="0">--select--</option>
                      <option value="झाड़ोल">झाड़ोल</option>
                      <option value="हेरा">हेरा</option>
                      <option value="चावंड">चावंड</option>
                      <option value="खेड़ा">खेड़ा</option>
                      <option value="सेमारी">सेमारी</option>
                    </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    
                  <label className="form-label" for="form3Example1n1">Membership type / सदस्यता प्रकार </label>
                  <select className="select form-control"  onChange={handleInput} name="MembershipType">
                  <option value="0">--select--</option>
                      <option value="वार्षिक">वार्षिक</option>
                      <option value="एक बार">एक बार</option>
                      <option value="भामाशाह">भामाशाह</option>
                    </select>
                  </div>
                  <div className="col-md-6 mb-4">
                  <div className="form-outline">
                      <label className="form-label" for="form3Example1m1">Amount / राशि </label>
                      <input type="number" id="form3Example1m1" onChange={handleInput} name="Amount" className="form-control form-control-lg" />
                    </div>

                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1m">Mode / मोड</label>
                      <select className="select form-control" onChange={handleInput} name="PaymentMode">
                      <option value="0">--select--</option>
                      <option value="कैश">कैश</option>
                      <option value="बैंक ट्रांसफर">बैंक ट्रांसफर</option>
                      <option value="यूपीआई">यूपीआई</option>
                    </select>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1n">Phone Number / फ़ोन नंबर</label>
                      <input type="number" id="form3Example1n" onChange={handleInput} name="PhoneNumber" maxLength={10} className="form-control form-control-lg" />
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1m">Accepted by / स्वीकृति द्वारा</label>
                      <input type="text" id="form3Example1m" onChange={handleInput} name="AcceptedBy" className="form-control form-control-lg" />
                    </div>
                  </div>
                  
                  {/* <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1n">Father name / पिता का नाम</label>
                      <input type="text" id="form3Example1n" className="form-control form-control-lg" />
                    </div>
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-md-12 mb-4 text-center">
                    <div className="form-outline">
                     <button className='btn btn-primary'>submit</button>
                    </div>
                  </div>
                 
                </div>

              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
  )
}

export default Home

import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import '../CSS/Receipt.css'
import html2pdf from 'html2pdf.js';
import { useParams } from 'react-router-dom';
import AmountToHindiWords from './AmountToHindiWords';
import logo from '../kuldevi.jpeg'

const Receipt = () => {
  let { id } = useParams();
    const [data, setData] = useState(null);
    const [errData,seterrData]=useState(null);
    

    useEffect(() => {
        fetchData();
      }, []);

  const fetchData = async () => {
            try {
              const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                  "id": id
                });

                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };
                  
                fetch("https://malecontechnologies.site/api/getData", requestOptions)
                  .then((response) => response.json())
                .then((result) => setData(result.memberLists))
                .catch((error) => seterrData(error));
                    
            } 
            catch (error) {
              seterrData('Error fetching data:', error);
            }

           
  };

  const downloadPDF = () => {
    const input = document.getElementById('rcpt');

    //========================
    html2pdf(input, {
      margin:       1,
      filename:     'your-filename.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { dpi: 192, letterRendering: true },
      jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
    });
    //=====================
    
    };
    
    console.log(data);

  return (
    <div>
    <section className="h-100">
  <div className="container py-5 h-100">
    
  <NavBar></NavBar>
  <div className="card text-danger">
      <div className="card-body">

        <div className='float-right pb-100'>
           <button className='btn btn-primary' onClick={downloadPDF}>Download as PDF</button>
        </div>
        <br /><br />

        {errData?
          <div> <h2>No Records Found </h2> {{errData}} </div> 
          :
          Array.isArray(data) &&  data.map((item)=>(
            <div class="container" id='rcpt'>
            <div className="row headers">
                <div className='col-md-4 col-4'>
                    <span>श्री कुलदेवी नमः !!</span>
                </div>
                <div className='col-md-4 col-4 justify-content-center'>
                   <span className=''> अवि.नं. 74/25.09.2009</span>
                </div>
                <div className='col-md-4 col-4'>
                    <span class="float-right"> श्री गणेशाय नमः !!</span>
                </div>
            </div>
        <br /><br />
              <div className='row'>
              <div className='col-md-2 col-2'>
                <img src={logo} alt="Logo" width="100px" height="100px" />
                   </div>                
                   <div className='col-md-10 col-10'>
                   
                <center><h1>कुलदेवी माँ आँजणा डॉगी (पटेल) सेवा संस्थान</h1></center>
                <center><h5>सत्ताड़ा, तह कराया, जिला-उदयपुर (राजी.)</h5></center>
                  </div>
              </div>

            {/* <div class="title">
                <h5>
                    <span>क्रमांक <input name="name" type="number" class="Receipt-input" /></span>
                    <span class="float-right">दिनांक <input name="name" type="date" class="Receipt-input" /></span>
                </h5>
            </div> */}
            <div class="i_row">
                <div class="i_to">
                    <div class="main_title">
                        <div class="row">
                            <div class="col-md-6 col-6">
                                  <div class="form-group">
                                    <label for="name" class="col-form-label">क्रमांक :</label> K-00{item.Id}
                                </div>
                            </div>

                            <div class="col-md-6 col-6">
                                  <div class="form-group">
                                  <label for="name" class="col-form-label">दिनांक </label>
                                  <input name="name" type="text" class="form-control custome-control" value={item.Date} readOnly />
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name" class="col-form-label labelwt">श्री/श्रीमती/मैसर्स :</label>
                                    <input type="text" class="form-control custome-control" id="name" value={item.PersonName} readOnly />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name" class="col-form-label labelwt">पिता का नाम :</label>
                                    <input type="text" class="form-control custome-control" id="name" value={item.FatherName} readOnly />
                                </div>
                            </div>


                        </div>
                        <div class="row">


                        <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name" class="col-form-label labelwt">निवासी :</label>
                                    <input type="text" class="form-control custome-control" id="name" value={item.VillageName} readOnly />
                                </div>
                            </div>

                            
                        <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name" class="col-form-label labelwt">से नकद/चेक/दी.दी :</label>
                                    <input type="text" class="form-control custome-control" id="name" value={item.ChaukleName} readOnly /> अक्षरे
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="name" class="col-form-label">रूपये </label>
                                    <input type="text" class="form-control custome-control" id="name" value={AmountToHindiWords(item.Amount)} />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="name" class="col-form-label">संस्थान विकास कार्य हेतु सधन्यवाद प्राप्त किया</label>
                                </div>
                            </div>
                            <br /><br /><br /><br /><br />
                        </div>
                        <div class="row">

                            <div class="col-md-4">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroup-sizing-default">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
                                              </svg>
                                </span>
                              </div>
                              <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" value={item.Amount+" /-"} readOnly />
                            </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group justify-content-center">
                                <br /><br />
                                ह. दानदाता
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group float-right">
                                <br /><br />
                                है. कोषाध्यक्ष
                                </div>
                            </div>



                            {/* <div class="col-md-6">
                                <span>संस्थान विकास कार्य हेतु सधन्यवाद प्राप्त किया</span>
                                <p><span> &#8377; <input name="name" type="number" class="Receipt-input" /></span><span class="float-right">है. कोषाध्यक्ष</span></p>
                            </div> */}
                        </div>
{/* 
                        <div>
                            <center><p>ह. दानदाता</p></center>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
          ))
      }

      </div>
    </div>

  </div>
  </section>
  </div>
  )
}

export default Receipt

function Validation(values)
{
    let error={}
    if(values.UserId==="")
    {
        error.UserId="UserId should not be empty";
    }
    else
    {
        error.UserId="";
    }
    if(values.password==="")
    {
        error.password="password should not be empty";        
    }
    else
    {
        error.password="";
    }

    return error;
}


export default Validation;
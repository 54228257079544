import React, { useEffect, useState } from 'react';
import NavBar from './NavBar';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateReceipt = () => {
    let { id } = useParams();
    const [data, setData] = useState(null);
    const [errData,seterrData]=useState(null);
    const [count,setCount]=useState(0);
    const [apiResp,setApiResp]=useState(null);
    const navigate=useNavigate();

    // const [values,setValues]=useState({
    //   id:id,
    //   Person_name:'',
    //   Father_name:'',
    //   Village_name:'',
    //   Chaukle_name:'',
    //   Membership_type:'',
    //   Amount:'',
    //   Payment_mode:'',
    //   Phone_number:'',
    //   Pmt_AcceptedBy:''
    // })

    const [values,setValues]=useState({
      id:id,
      PersonName:'',
      FatherName:'',
      Village:'',
      Chaukle:'',
      MembershipType:'',
      Amount:'',
      PaymentMode:'',
      PhoneNumber:'',
      AcceptedBy:''
    })

    useEffect(() => {
        console.warn(count);
        if(count==0)
        {
            fetchData();
            setCount(count+1);
        }
      }, []);

  const fetchData = async () => {
            try {
              const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                  "id": id
                });

                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };
                  
                fetch("https://malecontechnologies.site/api/getData", requestOptions)
                  .then((response) => response.json())
                .then((result) => {
                    
                    //setValues(result[0])
                    setValues(prevState => ({
                      ...prevState,
                      PersonName: result.memberLists[0].PersonName,
                      FatherName: result.memberLists[0].FatherName,
                      Village: result.memberLists[0].VillageName,
                      Chaukle: result.memberLists[0].ChaukleName,
                      MembershipType: result.memberLists[0].MembershipType,
                      Amount: result.memberLists[0].Amount,
                      PaymentMode: result.memberLists[0].PaymentMode,
                      PhoneNumber: result.memberLists[0].PhoneNumber,
                      AcceptedBy: result.memberLists[0].AcceptedBy
                    }));
                })
                .catch((error) => seterrData(error));
                    
            } 
            catch (error) {
              seterrData('Error fetching data:', error);
            }

           
  };
    
      const handleInput=(event)=>{
        console.warn(event.target.name +":"+event.target.value )
       setValues(prev=> ({...prev,[event.target.name]:event.target.value}))
      }


      const handleSubmit=(event)=>{
        event.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
      
        const raw = JSON.stringify(values);
        console.warn(raw);
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        fetch("https://malecontechnologies.site/api/updateData", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            
            setApiResp(result)
            console.warn(result);
            if(result.affectedRows)
            {
              if(result.affectedRows==1)
              {
                alert("Updated Successfully");
                navigate("/memberlist");
              }
              else
              {
                alert(result.message);
              }
            }
            else
            {
              alert("Something Went Wrong");
            }
            
            })
          .catch((error) => setApiResp(error));

          
      }


  return (
    <section className="h-100">
  <div className="container py-5 h-100">

  <NavBar></NavBar>

    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col">

      {/* {
      errData?
          <div> <h2>No Records Found </h2>  </div>
          :
          Array.isArray(data) &&  data.map((item)=>( */}

        <div className="card card-registration my-4">
          <div className="row g-0 justify-content-center">
           <form method='post' onSubmit={handleSubmit}>
            <div className="col-xl-12">
              <div className="card-body p-md-5 text-black">
                <h3 className="text-uppercase text-center">Generate New Receipt</h3>
                <h3 className="mb-5 text-uppercase text-center">नई रसीद बनाएं</h3>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label"   for="form3Example1m">Person name / दानदाता का नाम</label>
                      <input type="text" id="form3Example1m" onChange={handleInput} name="PersonName" value={values.PersonName} className="form-control form-control-lg" />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1n">Father name / पिता का नाम</label>
                      <input type="text" id="form3Example1n"  onChange={handleInput} name="FatherName" value={values.FatherName} className="form-control form-control-lg" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1m1">Village / गाँव </label>
                      <input type="text" id="form3Example1m1"  onChange={handleInput} name="Village" value={values.VillageName} className="form-control form-control-lg" />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1n1">Chaukle / चौकला </label>
                      <select className="select form-control"  onChange={handleInput} name="Chaukle" value={values.ChaukleName}>
                      <option value="झाड़ोल">झाड़ोल</option>
                      <option value="हेरा">हेरा</option>
                      <option value="चावंड">चावंड</option>
                      <option value="खेड़ा">खेड़ा</option>
                      <option value="सेमारी">सेमारी</option>
                    </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">

                  <label className="form-label" for="form3Example1n1">Membership type / सदस्यता प्रकार </label>
                  <select className="select form-control"  onChange={handleInput} name="MembershipType" value={values.MembershipType}>
                      <option value="वार्षिक">वार्षिक</option>
                      <option value="एक बार">एक बार</option>
                      <option value="भामाशाह">भामाशाह</option>
                    </select>
                  </div>
                  <div className="col-md-6 mb-4">
                  <div className="form-outline">
                      <label className="form-label" for="form3Example1m1">Amount / राशि </label>
                      <input type="number" id="form3Example1m1" onChange={handleInput} name="Amount" value={values.Amount} className="form-control form-control-lg" />
                    </div>

                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1m">Mode / मोड</label>
                      <select className="select form-control" onChange={handleInput} name="PaymentMode" value={values.PaymentMode}>
                      <option value="कैश">कैश</option>
                      <option value="बैंक ट्रांसफर">बैंक ट्रांसफर</option>
                      <option value="यूपीआई">यूपीआई</option>
                    </select>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1n">Phone Number / फ़ोन नंबर</label>
                      <input type="number" id="form3Example1n" onChange={handleInput} name="PhoneNumber" value={values.PhoneNumber} className="form-control form-control-lg" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1m">Accepted by / स्वीकृति द्वारा</label>
                      <input type="text" id="form3Example1m" onChange={handleInput} name="AcceptedBy" value={values.AcceptedBy} className="form-control form-control-lg" />
                    </div>
                  </div>
                  {/* <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <label className="form-label" for="form3Example1n">Father name / पिता का नाम</label>
                      <input type="text" id="form3Example1n" className="form-control form-control-lg" />
                    </div>
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-md-12 mb-4 text-center">
                    <div className="form-outline">
                     <button className='btn btn-primary'>Update Receipt</button>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            </form>
          </div>
        </div>
          {/* ))
                } */}

      </div>
    </div>
  </div>
</section>
  )
}

export default UpdateReceipt

import { Link } from "react-router-dom";
import logo from '../kuldevi.jpeg'


const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#"><img src={logo} alt="Logo" width="100px" height="100px" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/Home">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Home">Create New Receipt</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/MemberList">Member List</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link form-control btn btn-primary rounded submit px-3" to="/">Logout</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
  
  export default NavBar
  
import React, { useEffect, useState } from 'react'
import NavBar from './NavBar';
import { useNavigate,Link } from 'react-router-dom';

const MemberList = () => {

  const [ListData,setListData]=useState([]);
  const [err,setErr]=useState();
  const navigate=useNavigate();

  useEffect(()=>{
    getData();

  },[]);


  function getData()
  {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      "id": "0"
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
      
    fetch("https://malecontechnologies.site/api/getData", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setListData(result.memberLists);
      })
      .catch((error) => setErr(error));

  }

  function ViewDetails(id)
  {
    navigate("/Receipt");
  }

  return (
    <div>
    <section className="h-100">
  <div className="container py-5 h-100">
    
  <NavBar></NavBar>

  <div className="row d-flex justify-content-center align-items-center h-100">
      
      <div className="card card-registration my-4">
          <div className="row g-0 justify-content-center">
          <div className="col-xl-12">
              <div className="card-body p-md-5 text-black">
                    <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th scope="col">View Details</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Person name / दानदाता का नाम</th>
                        <th scope="col">Father name / पिता का नाम</th>
                        <th scope="col">Village / गाँव</th>
                      </tr>
                    </thead>
                    <tbody>
                      {err?
                      <div><tr>
                        <td colSpan={4}> No Records Found </td>
                        </tr></div>  :
                             Array.isArray(ListData) &&  ListData.map((item)=>(
                                <tr key={item.Id}>
                                <td><Link to={`/Receipt/${item.Id}`} className='btn btn-primary'>View</Link></td>
                                <td><Link to={`/UpdateReceipt/${item.Id}`} className='btn btn-primary'>Edit</Link></td>
                                <td>{item.PersonName}</td>
                                <td>{item.FatherName}</td>
                                <td>@{item.VillageName}</td>
                              </tr>

                            ))
                            // ListData
                    }
                      
                     
                     
                    </tbody>
                  </table>
              </div>
          </div>
          </div>
      </div>
      </div>
  </div>
  </section>
  </div>
  )
}

export default MemberList

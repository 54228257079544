import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/LoginSignup/Login';
 import Home from './components/CreateReceipt';
 import Receipt from './components/Receipt';
 import MemberList from './components/MemberList';
import UpdateReceipt from './components/UpdateReceipt';

function App() {
  return (
<BrowserRouter>
    <Routes>
      <Route path='/' element={<Login></Login>}></Route>
      <Route path='/Home' element={<Home></Home>}></Route>
      <Route path='/Receipt/:id' element={<Receipt></Receipt>}></Route>
      <Route path='/UpdateReceipt/:id' element={<UpdateReceipt></UpdateReceipt>}></Route>
      <Route path='/MemberList' element={<MemberList></MemberList>}></Route>
    </Routes>
</BrowserRouter>    
  );
}

export default App;

import '../../CSS/style.css';
import { Link,  useNavigate } from 'react-router-dom';
import Home from '../CreateReceipt';
import React, { useState } from 'react';
import Validation from './LoginValidation'
import NavBar from '../NavBar';

function Login() {
  const [values,setValues]=useState({
    UserId:'',
    password:''
  })

const navigate=useNavigate();

  const [errors,setErrors]=useState({});
  const [LoginStatus,setLoginStatus]=useState();
  const [LoginResponse,setLoginResponse]=useState();

  const handleInput=(event)=>{
    console.warn(event.target.name +":"+event.target.value )
   setValues(prev=> ({...prev,[event.target.name]:[event.target.value]}))
  }

  const handleSubmit=(event)=>{
      event.preventDefault();
      setErrors(Validation(values));
      if(errors.UserId==="" && errors.password==="")
      {
        //fetch('http://localhost:2300/login').then(response=> response.json()).then(data=> setLoginResponse(data.articles));
        //================
        const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          const raw = JSON.stringify({
            "UserId": values.UserId[0],
            "Password": values.password[0]
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          };

          fetch("https://malecontechnologies.site/api/Login", requestOptions)
            .then((response) => response.json())
            .then((result) => hnadleResponse(result))
            .catch((error) => hnadleResponse(error));
        //=================
        
        
      }
  }

  function hnadleResponse(response)
  {
    if(response.Status=="Success")
    {
      navigate('/home');
    }
    else
    {
      setLoginStatus(response.Message?response.Message:"Invalid Crdentials")
    }
  }

    return (
        <div className="App">
    {/* <NavBar></NavBar> */}
          <section className="ftco-section">          
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6 text-center mb-5">
                    <h2 className="heading-section" style={{ color: 'orange' }}>कुलदेवी माँ आँजणा डांगी (पटेल) सेवा संस्थान</h2>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12 col-lg-10">
                    <div className="wrap d-md-flex justify-content-center">
    
                      <div className="login-wrap p-4 p-md-5">
                        <div className="d-flex">
                          <div className="w-100">
                            <h3 className="mb-4">Sign In</h3>
                          </div>
    
                        </div>
                        <form action="#" onSubmit={handleSubmit} className="signin-form">
                          <div className="form-group mb-3">
                            <label className="label" htmlFor="name">Username</label>
                            <input type="text" onChange={handleInput} name="UserId" className="form-control" placeholder="Username" />
                            {errors.UserId && <span className='text-danger'>{errors.UserId}</span>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="label" htmlFor="password">Password</label>
                            <input type="password" onChange={handleInput} name="password" className="form-control" placeholder="Password" />
                            {errors.password && <span className='text-danger'>{errors.password}</span>}
                          </div>
                          <div className="form-group">
                          {LoginStatus && <span className='text-danger'>{LoginStatus}</span>}
                            <button type="submit" className="form-control btn btn-primary rounded submit px-3">Sign In</button>
                          </div>
                          <div className="form-group d-md-flex">
    
                            {/* <div className="w-50 text-md-right">
                              <a href="#">Forgot Password</a>
                            </div> */}
                          </div>
                        </form>
                        {/* <p className="text-center">Not a member?  */}
                        
                        {/* <Link to="/signup" >Sign Up</Link> */}
                        {/* <a data-toggle="tab" href="#signup">Sign Up</a> */}
                        {/* </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </section>
    
        </div>
      );
    }
    
    export default Login;